import { Button, Checkbox, Dialog, FormControlLabel, ListItemText } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material'
import { t } from 'i18next'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { MultipleLocationService } from '../../../../../api/locations/MultipleLocation'
import type { ILocation } from '../../../../../common/components/Maps'
import { campaignSelector } from '../../../../../core/store/reducers/campaign'
import { MAXIMUM_LOCATIONS_QUANTITY } from '../../../legacy/edit/schema'

export interface IGoogleLocation {
  id: string | null
  name: string
}

interface LocationSelectorDialogProps {
  open: boolean
  onClose: () => void
  onAdd: (locations: IGoogleLocation[]) => void
  locations: ILocation[]
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      width: '672px',
      borderRadius: '8px',
    },
    '& .MuiDialogTitle-root': {
      fontSize: '34px',
      fontWeight: 400,
      padding: '24px 16px 0 16px',
    },
    '& .MuiDialogContent-root': {
      padding: '24px 16px 0 16px',
    },
  },
  textFieldSearch: {
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.24)',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.24)',
      },
    },
  },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTypography-root': {
      fontWeight: 400,
      fontSize: '12px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  error: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiBox-root, .MuiTypography-root': {
      fontWeight: 400,
      fontSize: '12px',
      color: 'red',
    },
  },
  button: {
    color: 'white',
    backgroundColor: '#149600',
  },
  errorIcon: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-fontSizeMedium': {
      width: '16px',
      height: '16px',
      color: 'red',
    },
    '& .MuiTypography-root': {
      fontWeight: 400,
      fontSize: '12px',
      marginLeft: '4px',
      color: 'red',
    },
  },
}))

export const ModalAddLocations: FC<LocationSelectorDialogProps> = ({ open, onClose, onAdd, locations }) => {
  const classes = useStyles()
  const [isMatchedLocationsDialogOpen, setIsMatchedLocationsDialogOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [matchedLocations, setMatchedLocations] = useState<IGoogleLocation[]>([])
  const [unmatchedLocations, setUnmatchedLocations] = useState<string[]>([])
  const [selectedLocations, setSelectedLocations] = useState<IGoogleLocation[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const disabledLocationIds = locations.map((location) => `${location.id}${location.address}`)
  const { type } = useSelector(campaignSelector)
  const currentLocationsInput = !isMatchedLocationsDialogOpen ? searchQuery : unmatchedLocations.join('\n')
  const sortedMatchedLocations = [
    ...matchedLocations.filter(
      (location) =>
        !locations.some((existing) => `${existing.id}${existing.address}` === `${location.id}${location.name}`)
    ),
    ...matchedLocations.filter((location) =>
      locations.some((existing) => `${existing.id}${existing.address}` === `${location.id}${location.name}`)
    ),
  ]

  useEffect(() => {
    if (!isMatchedLocationsDialogOpen) {
      setError('')
      setSelectedLocations([])
      setMatchedLocations([])
    }
  }, [isMatchedLocationsDialogOpen])

  useEffect(() => {
    if (isMatchedLocationsDialogOpen && locations && locations.length > 0) {
      const existingLocations: IGoogleLocation[] = locations.map((location) => ({
        id: location.id ? location.id!.toString() : null,
        name: location.address,
      }))
      setMatchedLocations(existingLocations)
      setSelectedLocations(existingLocations)
    }
  }, [isMatchedLocationsDialogOpen, locations])

  const handleTextFieldChange = (value: string) => {
    if (isMatchedLocationsDialogOpen) {
      setUnmatchedLocations(value.split('\n').map((item) => item.trim()))
    } else {
      setSearchQuery(value)
    }
  }

  const searchLocations = async () => {
    setLoading(true)
    setIsMatchedLocationsDialogOpen(true)
    setError('')
    try {
      const zipCodes: string[] = isMatchedLocationsDialogOpen
        ? unmatchedLocations
        : searchQuery
            .split('\n')
            .map((item) => item.trim())
            .filter((item) => item)

      const { matchedCountries, unmatchedCountries } = await MultipleLocationService.searchLocations(zipCodes, type!)
      setMatchedLocations((prevMatched) => {
        const newLocations = matchedCountries.filter(
          (newLocation) => !prevMatched.some((existingLocation) => existingLocation.id === newLocation.id)
        )

        return [...prevMatched, ...newLocations]
      })
      setUnmatchedLocations(unmatchedCountries)

      if (unmatchedCountries.length > 0) {
        setError(
          t('addLocations.error', { unmatchedLength: unmatchedCountries.length, zipCodesLength: zipCodes.length })
        )
      }
      setLoading(false)
    } catch (err) {
      setError('An error occurred while searching for locations.')
      console.error(err)
    }
  }

  const resetDialogState = () => {
    setSearchQuery('')
    setError('')
    setMatchedLocations([])
    setUnmatchedLocations([])
    setSelectedLocations([])
  }

  const handleClose = () => {
    resetDialogState()
    setIsMatchedLocationsDialogOpen(false)
    onClose()
  }

  const handleCheckBoxToggle = (location: IGoogleLocation) => {
    setSelectedLocations((prevSelected) => {
      if (prevSelected.find((item) => item.id === location.id)) {
        return prevSelected.filter((item) => item.id !== location.id)
      }
      if (prevSelected.length < MAXIMUM_LOCATIONS_QUANTITY) {
        return [...prevSelected, location]
      }

      return prevSelected
    })
  }

  const handleSelectAllCheckBox = () => {
    const remainingCapacity = MAXIMUM_LOCATIONS_QUANTITY - locations.length
    const isSelectingAll = selectedLocations.length !== Math.min(matchedLocations.length, MAXIMUM_LOCATIONS_QUANTITY)
    const existingLocations: IGoogleLocation[] = locations.map((location) => ({
      id: location.id ? location.id!.toString() : null,
      name: location.address,
    }))

    if (isSelectingAll) {
      const uniqueLocationsToSelect = matchedLocations.filter(
        (location) => !locations.some((existingLocation) => String(existingLocation.id) === String(location.id))
      )
      const limitedLocations = uniqueLocationsToSelect.slice(0, remainingCapacity)
      setSelectedLocations([...existingLocations, ...limitedLocations])
    } else {
      setSelectedLocations([...existingLocations])
    }
  }

  const handleAddLocations = () => {
    const newLocations = selectedLocations.filter((location) => {
      const isDuplicateById = locations.some((existingLocation) => String(existingLocation.id) === String(location.id))

      const isDuplicateByName = locations.some(
        (existingLocation) => existingLocation.address.trim() === location.name.replace(/\d+/g, '').trim()
      )

      return !isDuplicateById && !isDuplicateByName
    })

    onAdd(newLocations)
    handleClose()
  }

  return (
    <>
      <Dialog fullWidth={true} maxWidth='sm' className={classes.root} open={open} onClose={handleClose}>
        <DialogTitle>
          {t('addLocations.title')}
          <IconButton
            edge='end'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
            sx={{
              color: 'rgba(0, 0, 0, 0.6)',
              position: 'absolute',
              right: 21,
              top: 11,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div></div>
        <DialogContent>
          <TextField
            sx={{
              position: 'relative',
              '& input': { textAlign: isLoading ? 'center' : 'left' },
            }}
            className={classes.textFieldSearch}
            multiline
            label={t('addLocations.zipCodesLabel')}
            fullWidth
            variant='outlined'
            minRows={5}
            value={isLoading ? '' : currentLocationsInput}
            onChange={(e) => handleTextFieldChange(e.target.value)}
            error={!!error}
            InputProps={{
              startAdornment: isLoading ? (
                <Box
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                >
                  <CircularProgress size={24} />
                </Box>
              ) : null,
            }}
          />
          <Box className={!error ? classes.description : classes.error}>
            {!searchQuery && !error ? (
              <Typography>{t('addLocations.label')}</Typography>
            ) : (
              <Typography>{error}</Typography>
            )}
            <Typography>
              {isMatchedLocationsDialogOpen ? unmatchedLocations.join('').length : searchQuery.length}/1000
            </Typography>
          </Box>

          <DialogActions>
            {!isMatchedLocationsDialogOpen && (
              <Button onClick={handleClose} variant='outlined'>
                {t('addLocations.cancelButton')}
              </Button>
            )}
            <Button disabled={!searchQuery} onClick={searchLocations} color='primary' variant='contained'>
              {!isMatchedLocationsDialogOpen ? t('addLocations.searchLocations') : t('addLocations.search')}
            </Button>
          </DialogActions>
          {isMatchedLocationsDialogOpen && (
            <>
              <Typography variant='h6' sx={{ mt: 2 }}>
                {t('addLocations.matchedLocations')}{' '}
                {locations.length > 0 ? matchedLocations.length - locations.length : matchedLocations.length}
              </Typography>
              <Typography variant='body2' color='textSecondary' sx={{ mb: 2 }}>
                {t('addLocations.pleaseSelectTargeting')}
              </Typography>
              <Typography variant='body2' sx={{ mb: 1 }}>
                {selectedLocations.length - locations.length} {t('addLocations.locationsSelected')}{' '}
                <Button
                  onClick={handleSelectAllCheckBox}
                  size='small'
                  color='primary'
                  disabled={matchedLocations.length === 0 || matchedLocations.length <= locations.length}
                >
                  {selectedLocations.length === Math.min(matchedLocations.length, MAXIMUM_LOCATIONS_QUANTITY)
                    ? t('addLocations.deselectAll')
                    : t('addLocations.selectAll')}
                </Button>
              </Typography>
              <List sx={{ maxHeight: 200, overflowY: 'auto', border: '1px solid #ccc', minHeight: 50 }}>
                {isLoading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', padding: 2 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  sortedMatchedLocations.map((location) => {
                    const locationId = `${location.id}${location.name}`
                    const isAlreadyAdded = disabledLocationIds.includes(locationId)
                    const isSelected = selectedLocations.some((item) => item.id === location.id)
                    const isDisabled =
                      isAlreadyAdded || (selectedLocations.length >= MAXIMUM_LOCATIONS_QUANTITY && !isSelected)

                    return (
                      <ListItem key={location.id} dense>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              disabled={isDisabled}
                              checked={isAlreadyAdded || isSelected}
                              onChange={() => !isAlreadyAdded && handleCheckBoxToggle(location)}
                            />
                          }
                          label={
                            <ListItemText
                              primary={
                                isAlreadyAdded
                                  ? t('addLocations.nameAlreadyAdded', { locationName: location.name })
                                  : t('addLocations.locationName', { locationName: location.name })
                              }
                            />
                          }
                        />
                      </ListItem>
                    )
                  })
                )}
              </List>
            </>
          )}
          {selectedLocations.length >= MAXIMUM_LOCATIONS_QUANTITY && isMatchedLocationsDialogOpen && (
            <Box className={classes.errorIcon}>
              <ErrorOutlineIcon />
              <Typography>
                {t('addLocations.maxLocationsError', { maxLocations: MAXIMUM_LOCATIONS_QUANTITY })}
              </Typography>
            </Box>
          )}
        </DialogContent>
        {isMatchedLocationsDialogOpen && (
          <DialogActions style={{ marginRight: '30px' }}>
            <Button onClick={handleClose} variant='outlined'>
              {t('addLocations.cancelButton')}
            </Button>
            <Button
              onClick={handleAddLocations}
              variant='contained'
              color='primary'
              disabled={selectedLocations.length > MAXIMUM_LOCATIONS_QUANTITY}
            >
              {t('addLocations.addLocationsButton')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

export default ModalAddLocations
