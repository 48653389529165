import type { TCampaignType } from '../../core/type'
import type { IGoogleLocation } from '../../pages/Campaigns/common/Components/multipleLocationsUpload/AddLocations'
import { searchGoogleLocations } from '../maps/ googleMaps'

export class MultipleLocationService {
  static async searchLocations(
    addresses: string[],
    type: TCampaignType
  ): Promise<{
    matchedCountries: IGoogleLocation[]
    unmatchedCountries: string[]
  }> {
    const matchedCountries: IGoogleLocation[] = []
    const unmatchedCountries: string[] = []

    for (const address of addresses) {
      try {
        const predictions = await searchGoogleLocations(address, type!)
        const location = predictions && predictions[0]
        if (location) {
          matchedCountries.push({
            id: location.place_id,
            name: location.description,
          })
        } else {
          unmatchedCountries.push(address)
        }
      } catch (err) {
        console.error(err)
        unmatchedCountries.push(address)
      }
    }

    return {
      matchedCountries,
      unmatchedCountries,
    }
  }

  // static async getPostalCodeByCoordinates(lat: number, lng: number): Promise<string | null> {
  //   const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  //   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
  //
  //   try {
  //     const response = await axios.get(url)
  //
  //     if (response.data.status === 'OK' && response.data.results.length > 0) {
  //       for (const result of response.data.results) {
  //         const postalCode = result.address_components.find((component: any) =>
  //           component.types.includes('postal_code')
  //         )?.long_name
  //
  //         if (postalCode) {
  //           return postalCode
  //         }
  //       }
  //     }
  //
  //     return null
  //   } catch (err) {
  //     console.error('Error fetching postal code by coordinates:', err)
  //
  //     return null
  //   }
  // }
}
