import { CountriesListUtil } from '../../common/components/Maps/Components/countriesListUtil'
import type { TCampaignType } from '../../core/type'

export const searchGoogleLocations = async (
  input: string,
  type: TCampaignType
): Promise<google.maps.places.AutocompletePrediction[]> => {
  const service = new google.maps.places.AutocompleteService()

  return new Promise((resolve, reject) => {
    const country = CountriesListUtil(type!)
    service.getPlacePredictions(
      { input, componentRestrictions: { country: country }, types: ['geocode'] },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
          resolve(predictions)
        } else {
          reject(status)
        }
      }
    )
  })
}
