import { makeStyles } from '@material-ui/styles'
import { Typography } from '@mui/material'
import { t } from 'i18next'
import type { FC } from 'react'
import React from 'react'

import locationIcon from '../../../../../common/components/Maps/icons/Icon.png'

interface AddLocationsInBulkProps {
  handleModalOpen: (isOpen: boolean) => void
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    marginTop: '16px',
    color: '#149600',
    fontSize: '14px',
    cursor: 'pointer',
    '& .MuiTypography-root': {
      marginLeft: '4px',
      fontSize: '14px',
      fontWeight: 600,
    },
  },
}))

export const AddLocationsInBulk: FC<AddLocationsInBulkProps> = ({ handleModalOpen }: any) => {
  const classes = useStyles()
  const handleDialogOpen = () => {
    handleModalOpen(true)
  }

  return (
    <div className={classes.root} onClick={handleDialogOpen}>
      <img alt={'icon'} src={locationIcon}></img>
      <Typography>{t('campaigns.addLocationsInBulk')}</Typography>
    </div>
  )
}

export default AddLocationsInBulk
